import { Injectable } from '@angular/core';
import { TranslateService } from '@app/shared/services/translate.service';

@Injectable({
    providedIn: 'root',
})
export class RouteTranslationTagsService {
    constructor(private translateService: TranslateService) {}

    async getRoutesTag() {
        return {
            'home-page': await this.translateService.getTranslation('HOME.Title'),
            admin: await this.translateService.getTranslation('GLOBAL.Administration'),
            'access-log': await this.translateService.getTranslation('MENU.AccessLog'),
            'usage-log': await this.translateService.getTranslation('MENU.UsageLog'),
            'deleted-log': await this.translateService.getTranslation('MENU.DeletedLog'),
            'user-groups': await this.translateService.getTranslation('MENU.UserGroups'),
            add: await this.translateService.getTranslation('DEFAULT_TEXT.AddAction'),
            update: await this.translateService.getTranslation('DEFAULT_TEXT.EditAction'),
            view: await this.translateService.getTranslation('DEFAULT_TEXT.ViewAction'),
            portfolio: await this.translateService.getTranslation('MENU.OrdersNotes'),
            'virtual-bin': await this.translateService.getTranslation('MENU.Virtual'),
            'work-center-capacity':
                await this.translateService.getTranslation('MENU.WorkCenterCapacity'),
            'component-lifecycle': await this.translateService.getTranslation('MENU.Components'),
            maintenance: await this.translateService.getTranslation('MENU.Maintenance'),
            account: await this.translateService.getTranslation('DEFAULT_TEXT.Account'),
            'user-settings': await this.translateService.getTranslation('USER.UserSettings'),
            planning: await this.translateService.getTranslation('PLANNING_ASSISTANT_PAGE.Title'),
            'step-one': await this.translateService.getTranslation(
                'PLANNING_ASSISTANT_PAGE.Title_Step1'
            ),
            'step-two': await this.translateService.getTranslation(
                'PLANNING_ASSISTANT_PAGE.Title_Step2'
            ),
            'step-three': await this.translateService.getTranslation(
                'PLANNING_ASSISTANT_PAGE.Title_Step3'
            ),
            simulator: await this.translateService.getTranslation('MENU.Simulator'),
            'functional-location-group': await this.translateService.getTranslation(
                'MENU.FunctionalLocationGroup'
            ),
            'functional-location-matrix': await this.translateService.getTranslation(
                'MENU.FunctionalLocationMatrix'
            ),
            select: await this.translateService.getTranslation('DEFAULT_TEXT.Select'),
            condition: await this.translateService.getTranslation('DEFAULT_TEXT.Conditions'),
            'special-conditions': await this.translateService.getTranslation(
                'FUNCTIONAL_LOCATION_MATRIX.SpecialConditions'
            ),
            import: await this.translateService.getTranslation('DEFAULT_TEXT.ImportAction'),
            'ioc-info': await this.translateService.getTranslation('MENU.ShutdownInfo'),
            form: await this.translateService.getTranslation('IOC.Form'),
            'maintenance-shutdown': await this.translateService.getTranslation(
                'MAINTENANCE_SHUTDOWN.MaintenanceShutdowns'
            ),
            'shutdown-management': await this.translateService.getTranslation(
                'MAINTENANCE_SHUTDOWN.MaintenanceShutdowns'
            ),

            'shutdown-strategies':
                await this.translateService.getTranslation('MENU.ShutdownStrategies'),
            kpi: await this.translateService.getTranslation('MENU.Kpi'),
            'physical-availability':
                await this.translateService.getTranslation('PRODUCTION_PLAN.Title'),
            'production-plan': await this.translateService.getTranslation('PRODUCTION_PLAN.Title'),
            user: await this.translateService.getTranslation('USER.Title'),
            filter: await this.translateService.getTranslation('DEFAULT_TEXT.Filter'),
            'route-not-found': await this.translateService.getTranslation(
                'DEFAULT_TEXT.RouteNotFound'
            ),
            'project-demand': await this.translateService.getTranslation('MENU.ProjectDemand'),
            'map-adherence': await this.translateService.getTranslation('MENU.MapAdherence'),
            'priorization-matrix': await this.translateService.getTranslation(
                'BREADCRUMB.PriorizationMatrix'
            ),
            'portfolio-process':
                await this.translateService.getTranslation('MENU.PortfolioByProcess'),
            notification: await this.translateService.getTranslation('BREADCRUMB.Notification'),
            notice: await this.translateService.getTranslation('BREADCRUMB.Notice'),
            faq: await this.translateService.getTranslation('BREADCRUMB.Faq'),
            workflow: await this.translateService.getTranslation('WORKFLOW.Breadcrumb'),
            'workflow-config': await this.translateService.getTranslation(
                'WORKFLOW_CONFIG.Breadcrumb'
            ),
            reports: await this.translateService.getTranslation('REPORTS.ReportName'),
            'automatic-email': await this.translateService.getTranslation('MENU.AutomaticEmail'),
            'maintenance-strategy': await this.translateService.getTranslation(
                'BREADCRUMB.MaintenanceStrategy'
            ),
            routeExists(route: string): boolean {
                const routeSplit = route.split('/');

                for (let index = routeSplit.length - 1; index > 0; index--) {
                    if (this.hasOwnProperty(routeSplit[index])) {
                        return true;
                    }
                }
                return false;
            },
        };
    }
}
